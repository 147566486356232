.input, .input_empty {
  border-radius: 16px 0px 0px 16px;
  background-color: #cccccc40;
  text-indent: 8px;
  width: 90%; /* Adjust as needed */
  padding: 8px;
  border: 1px solid transparent;
}

.input_focus {
  border-radius: 16px 0px 0px 0px;
  background-color: white;
  text-indent: 8px;
  width: 90%; /* Adjust as needed */
  padding: 8px;
  outline: none;
  border: 1px solid #ccc;
  border-bottom: 1px solid transparent;
  border-right: 1px transparent;
}

.input_select {
  border-radius: 16px 0px 0px 16px;
  background-color: #cccccc40;
  caret-color: white;
  text-indent: 8px;
  width: 100%; /* Adjust as needed */
  padding: 8px;
}

.input:focus, .input_select:focus {
  outline: none;
  border: 1px solid #ccc;
  text-indent: 8px;
  border-bottom: 1px solid transparent;
  border-right: 1px transparent;
  border-radius: 16px 0px 0px 0px;
  background-color: white;
  color: #aaa;
}

.input_empty:focus {
  outline: none;
  border: 1px solid #ccc;
  border-right: 1px #eee;
  text-indent: 8px;
  border-radius: 16px 0px 0px 0px;
  background-color: white;
  color: #aaa;
}

.input::placeholder, .input_empty::placeholder, .input_select::placeholder {
  color: #aaa;
  font-size: 16px;
  text-indent: 8px;
  font-weight: 300;
}

.input:focus::placeholder, .input_empty:focus::placeholder, .input_select:focus::placeholder {
  color: #ccc;
}

.menu_list {
  overflow-y: auto;
  position: absolute;
  top: 95%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 0 0 16px 16px;
  background-color: white;
  z-index: 2;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-top: 1px solid #eee;
}

.menu_item {
  padding: 8px;
  text-indent: 8px;
  cursor: pointer;
  /* margin-bottom: 2px; */
  border-bottom: 1px solid #eee;;
}

.menu_item_selected {
  padding: 8px;
  text-indent: 8px;
  cursor: pointer;
  background-color: #eee;
  border-bottom: 1px solid white;
}

.selector_container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.name_search_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 8px;
}

.name_search_item > p {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 90%;
  overflow-x: hidden;
}

.name_search_img_title{
  display: flex;
  gap: 16px;
}

.input_clear_span {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.input_clear {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #cccccc40;
  border-radius: 0px 16px 16px 0px;
  width: 10%;
}

.input_clear_focus {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 0px 16px 0px 0px;
  width: 10%;
  border: 1px solid #ccc;
  border-left: 1px transparent;
}

.name_search_item > img {
  width: 10px;
  height: 10px !important;
}

/* .selector_container:focus-within {
  box-shadow: 0 0 5px 0 #ccc;
  border-radius: 16px 0px 0px 16px;
} */
