.card_container {
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 0 4px #e0e0e0;
}

.card_container_top {
  padding: 24px;
  padding-bottom: 16px;
  padding-top: 16px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
}


.card_container_store_chips {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* height: 18px; */
}

.card_container_middle {
  display: flex;
  flex-direction: row;
}

.card_container_left {
  flex: 5;
  display: flex;
  max-width: 70%;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
}

.card_container_right {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-content: center;
  gap: 16px;
  /* align-items: end; */
}

.card_container_right > img {
  margin: auto;
}

.card_title {
  font-size: 16px;
  font-weight: bold;
  color: #444444;
  text-transform: uppercase;
  text-align: left !important;
  margin-bottom: 8px;
  margin-top: 8px;  
}

.card_price {
  font-size: 16px;
  font-weight: 500;
  color: #444444;
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  align-items: center;
}

.card_volume {
  font-size: 12px;
  font-weight: 300;
  color: #b8b7b7;
  margin-bottom: 8px;
}

.card_brand {
  font-size: 12px;
  font-weight: 300;
  color: #aaaaaa;
  margin-bottom: 8px;
}

.card_unit_input {
  width: 20%;
}

.card_unit_count_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 16px;
  justify-content: space-evenly;
  height: 32px;
  /* background-color: #CCCCCC; */
  width: 25%;
}

.unit_icon {
    background-color: #666;
  padding: 8px;
  border-radius: 8px;
}
.card_unit_count {
  padding-left: 8px;
  padding-right: 8px;

  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 31px;
}

.card_unit_button_right {
  border-radius: 0 8px 8px 0;
}

.card_add_button {
  font-size: 16px;
  background-color: #666666;
  color: white;
  border-radius: 16px;
  width: 40%;
  height: 32px;
}

.card_container_bottom {
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 16px;
  padding-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #cccccc;
  border-radius: 0 0 16px 16px;
}

.card_price_chip {
  font-size: 8px;
  align-self: center;
}

.card_chips {
  display: flex;
  flex-direction: row;
  justify-content: right;
  gap: 8px;
  width: 100%;
}

.card_remove_button {
  font-size: 16px;
  background-color: white;
  color: #b53b3b;
  border: 1px solid #b53b3b;
  border-radius: 16px;
  width: 40%;
  height: 32px;
  z-index: 101;
}
.login_alert {
  width: 60%;
}

.card_overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #aaaaaa40;
  z-index: 100;
  border-radius: 16px;
}

.card_value {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 8px
}

.card_title_highlight {
  color: rgb(246, 193, 113);
  width: fit-content;
  text-wrap: nowrap;
  text-transform: lowercase;
}