@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');

body {
    font-family: 'Open Sans', sans-serif;
}

.app_container {
    height: fit-content;
    width: 100vw;
    left: 0;
    top: 0;
    position: fixed;
    font-family: 'Open Sans';
    overflow-y: scroll;
    background-color: #eeeeee;
    /* scrollbar-width: 0px; */
}

.page_container {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    padding-top: 4rem;
    /* padding-bottom: 20rem; */
}