.cart_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: fit-content;
    padding-bottom: 16px;
    padding-top: 16px;
    overflow-x: hidden;
    gap: 16px
}

.empty_container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding-top: 128px;
    align-items: center;
}

.empty_content {
    width: 60%;
    height: 12%;
    box-shadow: 0 0 5px #cfcfcf;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    justify-content: space-around;
}

.empty_content>text {
    font-size: 16px;
    color: #AAAAAA;
    font-weight: 300;
}

.cart_header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cart_header_row {
    width: 95vw;
    padding: 8px;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: 0 0 2px #CCCCCC;
    background-color: #FFF;
    border-radius: 16px;
}

.cart_header_text {
    font-size: 16px;
    color: #AAAAAA;
    font-weight: 300;
    /* text-transform: uppercase; */
}

.cart_header_span {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.cart_list {
    width: 90%;
    padding: 16px;
    box-shadow: 0 0 5px rgb(207, 207, 207);
    border-radius: 8px;
    background-color: white;
}

.cart_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 0.5px dotted #CCCCCC;
}

.cart_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 75%;
    gap: 8px;
}

.cart_item_text {
    font-size: 12px;
    color: #AAAAAA;
    font-weight: 300;
    text-transform: uppercase;
    white-space: nowrap;
    /* Prevent text from wrapping to the next line */
    overflow: hidden;
    /* Hide any overflow text */
    text-overflow: ellipsis;
    /* Show an ellipsis (...) when text overflows */
}

.cart_item_price {
    font-size: 12px;
    color: #444444;
    font-weight: 400;
    text-transform: uppercase;
    font-weight: bold;
}

.cart_item_count {
    font-size: 12px;
    color: #AAAAAA;
}

.cart_savings {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.cart_savings_span {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    box-shadow: 0 0 2px #CCCCCC;
    width: 95vw;
    padding: 8px;
    background-color: white;
    border-radius: 16px;
}

.cart_savings_text {
    font-size: 16px;
    color: #AAAAAA;
    text-indent: 8px;
    /* text-transform: uppercase; */
}

.cart_edit {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    gap: 8px;
}

.cart_savings_dropdown {
    background-color: white;
    width: 100%;
    border-radius: 0 0 16px 16px;
    border-top: 1px dotted #AAAAAA;
    display: flex;
    flex-direction: row;
}

.cart_savings_dropdown_left {
    width: 50%;
    padding: 16px;
    gap: 8px;
    display: flex;
    flex-direction: column;
    border-right: 1px dotted #AAAAAA;
}

.cart_savings_dropdown_right {
    width: 50%;
    display: flex;
    padding: 16px;
    gap: 8px;
    flex-direction: column;
    align-items: start;
}

.cart_savings_span_dropdown {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 8px;
}

.cart_savings_text_price {
    display: flex;
    flex-direction: row;
    gap: 8px
}

.cart_savings_list_text {
    font-size: 12px;
    color: #AAAAAA;
    font-weight: 300;
    text-transform: uppercase;
}

.cart_savings_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0 2px #CCCCCC;
    width: 95vw;
    /* padding: 8px; */
    background-color: white;
    border-radius: 16px
}

.cart_savings_title {
    white-space: nowrap;
    /* Prevent text from wrapping to the next line */
    overflow: hidden;
    /* Hide any overflow text */
    text-overflow: ellipsis;
}