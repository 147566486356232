.nav_container {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: start;
  width: 60%;
  height: 100vh;
  position: fixed;
  top: 0%;
  background-color: #555555;
  z-index: 1000;
}

.nav_link {
  text-decoration: none;
  color: white;
  font-size: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  padding-left: 32px;
  padding-right: 24px;
}

.nav_user {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 2.5rem;
    position: relative;
    left: 4%;
    width: 96%;
    border-radius: 16px 0 0 16px;
    box-shadow: 0 0 10px #777777;
    border-right: none;
    padding: 16px;
    margin-bottom: 2rem;
}

.nav_user > text {
    font-size: 16px;
    color: white;
}

.nav_link_icon {
    position: absolute;
    left: 32px;
}

.nav_close {
  justify-content: right;
  position: relative;
  /* background-color: #333333; */
  height: 4rem;
}

.nav_link > text {
  font-size: 16px;
  color: #ddd;
}

.selected {
  background-color: #777777;
  color: #4e4e4e;
  /* border-top: 3px solid #4e4e4e; */
}

.top_nav_container {
  height: 4rem;
  width: 100vw;
  display: flex;
  justify-content: center;
  background-color: #555555;
  align-items: center;
  position: fixed;
  z-index: 999;
  color: white;
  font-size: 24px;
}

.top_nav_container_cart {
  align-self: center;
}

.top_nav_container_menu {
  margin-left: 1rem;
  position: absolute;
  left: 0;
}

.nav_backdrop {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.cart_span {
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 3%;
  gap: 4px;
  height: 32px;
  width: 32px;
  /* background-color: red; */
}