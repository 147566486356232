.login_page_container {
    background-color: #4e4e4e;
    font-family: Arial;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
}

.form_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    height: 80vh;
}

.form_container > h1 {
    font-size: 24px;
    color: white;
    font-weight: bold;
}

.form_container > p {
    font-size: 1rem;
    color: white
}

.form_container > input {
    padding: 8px;
    text-indent: 8px;
    border: none;
    border-radius: 16px;
    width: 60%;
}

.form_container > input:focus {
    outline: none;
}

.form_container > span {
    width: 60%;
    display: flex;
    justify-content: space-between;
}

.form_container > span > input {
    padding: 8px;
    text-indent: 8px;
    border: none;
    border-radius: 16px;
    width: 48%;
    box-shadow: #ccc 0px 0px 3px;
}

.form_container > input::placeholder, .form_container > span > input::placeholder {
    color: #4e4e4e;
    font-weight: 500;
}

.form_button {
    padding: 12px;
    border: none;
    border-radius: 16px;
    background-color: #4e4e4e;
    color: #CCC;
    border: 1px solid #CCC;
}

.login_link {
    color: rgb(196, 196, 196);
    margin-left: 8px;
}

.login_alert {
    width: 60%;
}

.mobile_message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: #4e4e4e;
    color: white
}