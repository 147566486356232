.search_container {
    width: 100vw;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 16px;
    padding-bottom: 160px;
    gap: 16px;
}

.search_input_container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    padding-top: 0;
}

.shop_text{
    color: #CCC;
    font-size: 16px;
}

.search_input_container > span > input {
    border: none;
    padding: 8px;
    border-radius: 16px;
    border: 1px solid #AAAAAA;
    font-size: 16px;
    color: #4e4e4e;
}

.store_select {
    color: #444444;
    font-size: 16px;
    font-weight: 100;
    /* text-indent: 16px; */
}

.search_input_container > span > input::placeholder, .search_input_container > span > input {
    color: #444444;
    font-size: 16px;
    font-weight: 100;
    /* text-indent: 8px; */
}

.search_input_container > input:focus, .search_input_container > span > input:focus {
    outline: none;
}

.store_dropdown {
    font-size: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 80%;
}

.text_container {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: left;
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid #DDD;
}

.store_content_container {
    box-shadow: 0 0 5px rgb(207, 207, 207);
    border-radius: 16px;
    padding: 16px;
    width: 90%;
    background-color: white;
}

.shop_result_list {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    gap:  16px;
    overflow-y: scroll;
}

.bp5-control.bp5-checkbox{
    color: #444444;
    font-size: 16px;
    font-weight: 300;
  }

.card_spinner {
    color: #CCCCCC;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.store_search_button {
    padding: 8px;
    font-size: 16px;
    border-radius: 16px;
    background-color:  #666666;
    color: white;
    width: 100%;
    height: 40px;
}

.store_clear_button {
    padding: 8px;
    font-size: 16px;
    border-radius: 16px;
    border: 1px solid #666666;
    background-color:  transparent;
    color: #666666;
    width: 100%;
    height: 40px; 
}

.store_search_button_disable {
    padding: 8px;
    font-size: 16px;
    border-radius: 16px;
    background-color:  #CCCCCC;
    color: white;
    width: 100%;
    height: 40px;
}

.search_button_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.store_select {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.bp5-control.bp5-checkbox > input:checked {
    color: red;
    outline: blue;
}

.volume_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #cccccc40;
    border-radius: 16px;
    width: 100%;
    padding-left: 8px;
    padding-right: 16px;
    height: 42px;
}

.unit_menu {
    min-width: 0% !important;
    max-width: 200% !important;
    width: 120%
}

.volume_container_text {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 90%;
}

.volume_min_max {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    width: 40%;
}

.volume_min_max > text, .volume_container_text > text {
    font-size: 16px;
    color: #aaa;
}

.volume_min_max > input {
    /* background-color: #f7f7f7; */
    background-color: transparent;
    /* border-left: 1px solid black; */
    padding: 8px;
    width: 50%;
    text-align: center;
}

.volume_min_max > input:focus {
    outline: none;
}

.custom_slider {
    z-index: 1;
    border-radius: 16px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    padding-left: 16px !important;
    padding-right: 16px !important;
    height: 42px;
    width: 100%;
}

.button_container {
    display: flex;
    flex-direction: column;
    width: 90%;
    gap: 8px;
    justify-content: center;
}

/* .unit_store {
    min-width: 242% !important;
    max-width: 242% !important;
    width: 242% !important;
}

.unit_category {
    min-width: 200% !important;
    max-width: 200% !important;
    width: 200% !important;
} */