.cart_overlay {
   background-color: white;
   width: 90vw;
   border-radius: 8px;
   box-shadow: 0 0 5px #CCCCCC;
}

.card_modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent black background */
    backdrop-filter: blur(0.25px); /* Apply a blur effect to the background */
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay_content {
    padding: 24px;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.overlay_text {
    font-size: 8px;
    color: #AAAAAA;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
}

.overlay_product {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    padding-bottom: 8px;
}

.overlay_product_right {
    flex: 2;
    height: 100%;
}

.overlay_product_left {
    flex: 5;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    padding-right: 8px;
    height: 100%;
}

.overlay_product_title {
    font-size: 16px;
    font-weight: bold;
    color: #444444;
    text-transform: uppercase;
}

.overlay_product_brand {
    font-size: 16px;
    color: #AAAAAA;
}

.overlay_product_price {
    font-size: 16px;
    color: #444444;
}

.overlay_product_buttons {
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 8px;
    width: 100%;
    padding-top: 8px;
}

.overlay_find_button {
    font-size: 16px;
    background-color:  white;
    color: #444444;
    border: 1px solid #444444;
    border-radius: 8px;
    width: 40%;
    height: 32px;
}

.overlay_remove_button {
    font-size: 16px;
    background-color:  #b53b3b;
    color: white;
    border-radius: 8px;
    width: 30%;
    height: 32px;
}

.overlay_counter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    height: 30px;
    background-color: #AAAAAA;
    width: 40%;
}

.overlay_apply_button {
    font-size: 16px;
    background-color:  #3bb56c;
    color: white;
    border-radius: 8px;
    width: 30%;
    height: 32px;
}

.overlay_content_header {
    display: flex;
    flex-direction: row;
    justify-content:space-between;
}

.edit_button {
    background-color: white;
    border-radius: 8px;
    padding: 8px;
}